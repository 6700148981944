.loadingMessageContainer{
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
}

.loadingMessage{
    display: block;
    font-size: 30px;
    color: grey;
}