.resourceIconForeignObject{
    overflow:visible;
}

.resourceIcon{
    font-size: 6px;
    padding-top: 0.2em;
    text-align:center;

    filter: drop-shadow(1px 2px 1px rgb(0 0 0 / 0.4));
    background-color: #f07655;


    border-radius: 50%;
    color: black;

    pointer-events: auto;
    cursor: pointer;
}

.minusculeScale{
    border-radius: 50%;
    color: transparent;
    transition: 0.1s ease-in-out;
}

.notMinusculeScale{
    border-radius: 4px;
    color: black;
    transition: 0.1s ease-in-out;
}



.isConnected{
    filter: brightness(130%);
    background-color: #f15125;
}


.delayedLightUp{
    animation: delayedLightUp 0.4s;
}


@keyframes delayedLightUp{
    0%{
        filter: brightness(60%);
        background-color: #f07655;
    }
    50%{
        filter: brightness(60%);
        background-color: #f07655;
    }
    100%{
        filter: brightness(130%);
        background-color: #f15125;
    }
}
