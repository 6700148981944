svg {
    width: 100%;
    height: 100%;
}

/* #treeContainerG{
    opacity: 0;
} */

.delayedInvisability {
    opacity: 0;
    transition: 0.1s ease-in-out;

}

.fadeIn{
    transition: 0.1s ease-in-out;
    opacity: 1 !important;
}

g{
    outline: none !important;
}

foreignObject{
    outline: none !important;
}

div{
    outline: none !important;
}

.pointerEventLess{
    pointer-events: none;
}

.zoom-in-cursor{
    cursor: zoom-in;
}

.zoom-out-cursor{
    cursor: zoom-out;
}

.drop-shadowed-bush{
    filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4));
}
