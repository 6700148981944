.bushNode{
    background-color: #a6ca84;
    border-radius: 2em;

}



.bushNodeOpacityControlContainer{
    width: 100%;
    height: 100%;
    opacity: 1;


}


.fadeInOut-enter{
    opacity: 0;
}

.fadeInOut-enter-active{
    opacity: 1;
    transition: 0.15s ease-out;
}

.fadeInOut-exit{
    opacity: 1;
}

.fadeInOut-exit-active{
    opacity: 0;
    transition: 0.15s ease-out;
}



