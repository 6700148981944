

#originNode{
    display:flex;
    flex-direction:column;
    align-items:center;
    
    width:100%;
    height:100%;
    overflow: visible;
}

.shadowHider{
    width: 25px;
    flex-grow: 1;
    background-color:#8f6b4d;
    pointer-events:none;
}
