

#App {
  /* text-align: center; */
  /* display: flex;
  align-items: center;
  justify-content: center; */
  /* height: 100%; */

  /* pointer-events: none; */
  height: 100vh;
  width: 100%;
}

.centeringContainer{

  /* width: ${props => (`${props.availableTreeWidth}px`)};
  height: ${props => (`${props.availableTreeHeight}px`)}; */
  /* position: absolute; */
  /* border: 1px solid green; */

  position:absolute;

  width:100%;
  height: 100%;
}


#hoverPanDetector{
  height: 100vh;
  padding: 5px;
}


.nav-icon{
  display: inline-block;
  width:30px;
  height: 30px;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  background-position: center;
  background-size: 100%;
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
