


#rootNode{
    background-color: rgb(189, 89, 23);
    border-radius: 0px 0px 1em 1em;
    display:flex;
    align-items:center;
    justify-content:center;
}

.potText{
    font-size:30px;
}